var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"card-title font-weight-bolder"},[_vm._v("Verlauf")]),_c('div',{staticClass:"card-toolbar d-flex flex-row-reverse"},[_c('b-form-select',{attrs:{"options":_vm.dateRangeOptions},model:{value:(_vm.selectedDateRange),callback:function ($$v) {_vm.selectedDateRange=$$v},expression:"selectedDateRange"}}),_c('b-form-checkbox',{staticClass:"ml-3",model:{value:(_vm.isMergeChangesEnable),callback:function ($$v) {_vm.isMergeChangesEnable=$$v},expression:"isMergeChangesEnable"}},[_vm._v("Änderungen zusammenfassen")])],1)]),_c('div',{staticClass:"card-body"},[(!_vm.isLoading)?_c('div',{staticClass:"timeline timeline-3"},[_c('div',{staticClass:"timeline-items"},_vm._l((_vm.auditingLogsDisplay),function(item,index){return _c('div',{key:index,staticClass:"timeline-item"},[_c('div',{staticClass:"timeline-media"},[_c('i',{staticClass:"flaticon2-right-arrow",class:{
                  'text-warning': item.event == 'Modified',
                  'text-danger': item.event == 'Deleted',
                }})]),_c('div',{staticClass:"timeline-content"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between mb-3"},[_c('div',{staticClass:"mr-2"},[_c('a',{staticClass:"text-dark-75"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$store.getters.getUsernameById(item.userId) || 'System'))]),_vm._v(" hat eine Änderung vorgenommen. ")]),(item.event)?_c('span',{staticClass:"label font-weight-bolder label-inline ml-2",class:[
                      { 'label-light-warning': item.event === 'Modified' },
                      { 'label-light-danger': item.event === 'Deleted' },
                      { 'label-light-success': item.event === 'Added' },
                    ]},[_vm._v(_vm._s(item.event))]):_vm._e()]),_c('div',{staticClass:"ml-2"},[_c('div',{staticClass:"text-muted ml-2"},[_vm._v(_vm._s(item.formattedTime)+" Uhr")])])]),_c('div',{staticClass:"timeline-changes w-100",class:item.event !== 'Added' ? '' : 'bg-white p-3'},[(item.newValue !== null && item.oldValue !== null)?_c('span',[_c('b-icon-pencil-fill',{attrs:{"variant":"warning"}}),_vm._v(" Änderte "),_c('b',[_vm._v(_vm._s(_vm.$t('COLUMNNAMES.' + item.key)))]),_vm._v(" von "),_c('span',{staticClass:"font-italic font-weight-light"},[_vm._v(_vm._s(item.newValue))]),_c('b',[_vm._v(" zu ")]),_c('span',{staticClass:"font-italic font-weight-light"},[_vm._v(_vm._s(item.oldValue))])],1):(item.newValue !== null && item.oldValue === null)?_c('span',[_c('b-icon-file-earmark-plus',{attrs:{"variant":"success"}}),_vm._v(" Erstellte "),_c('b',[_vm._v(_vm._s(_vm.$t('COLUMNNAMES.' + item.key)))]),_vm._v(" mit "),_c('span',{staticClass:"font-italic font-weight-light"},[_vm._v(_vm._s(item.newValue))])],1):(item.newValue === null && item.oldValue !== null)?_c('span',[_c('b-icon-trash-fill',{attrs:{"variant":"danger"}}),_vm._v(" Entfernte "),_c('b',[_vm._v(_vm._s(_vm.$t('COLUMNNAMES.' + item.key)))]),_vm._v(" vorher: "),_c('span',{staticClass:"font-italic font-weight-light"},[_vm._v(" "+_vm._s(item.oldValue))])],1):_vm._e()])])])}),0)]):_vm._e(),(_vm.isLoading)?[_c('b-skeleton',{staticClass:"mb-5",attrs:{"height":"100px","width":"100%"}}),_c('b-skeleton',{staticClass:"mb-5",attrs:{"height":"100px","width":"100%"}}),_c('b-skeleton',{staticClass:"mb-5",attrs:{"height":"100px","width":"100%"}})]:_vm._e(),(!_vm.isLoading)?_c('LoadSpinnerWidget',{attrs:{"dataAvailable":_vm.auditingLogsDisplay.length > 0,"isLoading":_vm.isLoading}}):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }