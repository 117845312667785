<template>
  <div>
    <div class="card card-custom">
      <div class="card-header">
        <h3 class="card-title font-weight-bolder">Verlauf</h3>
        <div class="card-toolbar d-flex flex-row-reverse">
          <b-form-select v-model="selectedDateRange" :options="dateRangeOptions"></b-form-select>
          <b-form-checkbox v-model="isMergeChangesEnable" class="ml-3"
            >Änderungen zusammenfassen</b-form-checkbox
          >
        </div>
      </div>
      <div class="card-body">
        <div v-if="!isLoading" class="timeline timeline-3">
          <div class="timeline-items">
            <div class="timeline-item" v-for="(item, index) in auditingLogsDisplay" :key="index">
              <div class="timeline-media">
                <i
                  class="flaticon2-right-arrow"
                  :class="{
                    'text-warning': item.event == 'Modified',
                    'text-danger': item.event == 'Deleted',
                  }"
                ></i>
              </div>
              <div class="timeline-content">
                <div class="d-flex align-items-center justify-content-between mb-3">
                  <div class="mr-2">
                    <a class="text-dark-75">
                      <span class="font-weight-bold">{{
                        $store.getters.getUsernameById(item.userId) || 'System'
                      }}</span>
                      hat eine Änderung vorgenommen.
                    </a>
                    <span
                      v-if="item.event"
                      class="label font-weight-bolder label-inline ml-2"
                      :class="[
                        { 'label-light-warning': item.event === 'Modified' },
                        { 'label-light-danger': item.event === 'Deleted' },
                        { 'label-light-success': item.event === 'Added' },
                      ]"
                      >{{ item.event }}</span
                    >
                  </div>
                  <div class="ml-2">
                    <div class="text-muted ml-2">{{ item.formattedTime }} Uhr</div>
                  </div>
                </div>
                <div class="timeline-changes w-100" :class="item.event !== 'Added' ? '' : 'bg-white p-3'">
                  <span v-if="item.newValue !== null && item.oldValue !== null">
                    <b-icon-pencil-fill variant="warning"></b-icon-pencil-fill> Änderte
                    <b>{{ $t('COLUMNNAMES.' + item.key) }}</b> von
                    <span class="font-italic font-weight-light">{{ item.newValue }}</span>
                    <b> zu </b>
                    <span class="font-italic font-weight-light">{{ item.oldValue }}</span></span
                  >
                  <span v-else-if="item.newValue !== null && item.oldValue === null">
                    <b-icon-file-earmark-plus variant="success"></b-icon-file-earmark-plus> Erstellte
                    <b>{{ $t('COLUMNNAMES.' + item.key) }}</b> mit
                    <span class="font-italic font-weight-light">{{ item.newValue }}</span></span
                  >
                  <span v-else-if="item.newValue === null && item.oldValue !== null">
                    <b-icon-trash-fill variant="danger"></b-icon-trash-fill> Entfernte
                    <b>{{ $t('COLUMNNAMES.' + item.key) }}</b> vorher:
                    <span class="font-italic font-weight-light"> {{ item.oldValue }}</span></span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <template v-if="isLoading">
          <b-skeleton class="mb-5" height="100px" width="100%"></b-skeleton>
          <b-skeleton class="mb-5" height="100px" width="100%"></b-skeleton>
          <b-skeleton class="mb-5" height="100px" width="100%"></b-skeleton>
        </template>
        <LoadSpinnerWidget
          v-if="!isLoading"
          :dataAvailable="auditingLogsDisplay.length > 0"
          :isLoading="isLoading"
        ></LoadSpinnerWidget>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from '@/core/common/services/api.service';

import LoadSpinnerWidget from '@/components/common/load-spinner-widget.vue';

export default {
  name: 'ReiseterminViewHistoryComponent',
  components: { LoadSpinnerWidget },
  props: {
    reiseterminObj: Object,
  },
  data() {
    return {
      auditingLogsDisplay: [],
      isMergeChangesEnable: true,
      parsedHistoryData: null,
      isLoading: true,
      selectedDateRange: 1,
    };
  },
  computed: {
    dateRangeOptions() {
      // Get the current date
      const currentDate = new Date();
      // Calculate the start date for last month
      const lastMonthStartDate = new Date(currentDate);
      lastMonthStartDate.setMonth(currentDate.getMonth() - 1);
      // Calculate the start date for last year
      const lastYearStartDate = new Date(currentDate);
      lastYearStartDate.setFullYear(currentDate.getFullYear() - 1);
      // Calculate the start date for last three months
      const lastThreeMonthsStartDate = new Date(currentDate);
      lastThreeMonthsStartDate.setMonth(currentDate.getMonth() - 3);
      // Calculate the start date for last week
      const lastWeekStartDate = new Date(currentDate);
      lastWeekStartDate.setDate(currentDate.getDate() - 7);
      // Calculate the date for yesterday
      const yesterday = new Date(currentDate);
      yesterday.setDate(currentDate.getDate() - 1);

      // Create the options array with the desired date ranges
      return [
        {
          value: 0,
          start: yesterday,
          text: 'Gestern',
        },
        {
          value: 1,
          start: lastWeekStartDate,
          text: 'Letzte Woche',
        },
        {
          value: 2,
          end: new Date(currentDate),
          text: 'Letzter Monat',
        },
        {
          value: 3,
          start: lastThreeMonthsStartDate,
          text: 'Letzte 3 Monate',
        },
        {
          value: 4,
          start: lastYearStartDate,
          text: 'Letztes Jahr',
        },
      ];
    },
  },
  watch: {
    reiseterminObj: {
      immediate: true,
      handler(v) {
        if (v) {
          this.loadHistory();
        }
      },
    },
    selectedDateRange: {
      handler(v) {
        this.loadHistory();
      },
    },
    isMergeChangesEnable: {
      handler(v) {
        if (this.isMergeChangesEnable) {
          const mergedChanges = this.mergeChangesWithSameKey(this.parsedHistoryData);
          this.auditingLogsDisplay = mergedChanges;
        } else {
          this.auditingLogsDisplay = this.parsedHistoryData;
        }
      },
    },
  },
  mounted() {},
  methods: {
    async loadHistory() {
      this.isLoading = true;
      const rawHistoryData = await this.fetchAndMergeHistories();
      this.parsedHistoryData = this.parseAllChanges(rawHistoryData);
      if (this.isMergeChangesEnable) {
        const mergedChanges = this.mergeChangesWithSameKey(this.parsedHistoryData);
        this.auditingLogsDisplay = mergedChanges;
      } else {
        this.auditingLogsDisplay = this.parsedHistoryData;
      }
      this.isLoading = false;
    },
    parseAllChanges(historyArray) {
      // Flatten the historyArray by iterating through the entries (key-value pairs)
      // and mapping each change object within the array of changes
      return Object.entries(historyArray).flatMap(([timestamp, changes]) => {
        // For each entry, map the changes array and return new objects with the following properties
        return changes.map(change => {
          // Determine the changeType based on the oldValue and newValue properties
          let changeType;
          if (change.oldValue === null && change.newValue !== null) {
            changeType = 'Added';
          } else if (change.oldValue !== null && change.newValue === null) {
            changeType = 'Deleted';
          } else {
            changeType = 'Modified';
          }
          return {
            // Format the timestamp using the dateTime filter
            formattedTime: this.$options.filters.dateTime(timestamp),
            // Get the key property from the change object
            key: change.key,
            // If oldValue and key exist in change, parse the oldValue using parseValuesByKeyName, otherwise set to null
            oldValue:
              change.oldValue && change.key ? this.parseValuesByKeyName(change.key, change.oldValue) : null,
            // If newValue and key exist in change, parse the newValue using parseValuesByKeyName, otherwise set to null
            newValue:
              change.newValue && change.key ? this.parseValuesByKeyName(change.key, change.newValue) : null,
            // Add the changeType field based on the conditions above
            changeType: changeType,
          };
        });
      });
    },
    mergeChangesWithSameKey(changes) {
      const mergedChanges = [];

      changes.forEach(change => {
        const existingChangeIndex = mergedChanges.findIndex(
          mergedChange => mergedChange.key === change.key && mergedChange.changeType === change.changeType
        );

        if (existingChangeIndex !== -1) {
          const firstChangeTimestamp = mergedChanges[existingChangeIndex].firstChangeTimestamp;
          const lastChangeTimestamp = mergedChanges[existingChangeIndex].lastChangeTimestamp;

          // Check if the current change is older than the first change
          if (change.formattedTime < firstChangeTimestamp) {
            mergedChanges[existingChangeIndex].firstChangeTimestamp = change.formattedTime;
          }

          // Check if the current change is newer than the last change
          if (change.formattedTime > lastChangeTimestamp) {
            mergedChanges[existingChangeIndex].lastChangeTimestamp = change.formattedTime;
            mergedChanges[existingChangeIndex].oldValue = change.oldValue;
          }

          // Update the formattedTime with the first and last change timestamps
          mergedChanges[existingChangeIndex].formattedTime =
            lastChangeTimestamp + ' - ' + firstChangeTimestamp;
        } else {
          mergedChanges.push({
            ...change,
            firstChangeTimestamp: change.formattedTime,
            lastChangeTimestamp: change.formattedTime,
          });
        }
      });

      return mergedChanges;
    },
    fetchAndMergeHistories() {
      const startDate =
        this.dateRangeOptions.find(option => option.value === this.selectedDateRange)?.start?.toISOString() ||
        null;
      return Promise.all([
        this.getReiseterminHistory(startDate),
        this.getReiseterminMetadataHistory(startDate),
      ]).then(([response1, response2]) => {
        return this.mergeResponses({ result: response1 }, { result: response2 });
      });
    },
    mergeResponses(response1, response2) {
      // Create a shallow mergedResult object by merging response1.result and response2.result
      let mergedResult = { ...response1.result, ...response2.result };

      // Iterate through the keys of response2.result
      for (const key in response2.result) {
        // Check if the key exists in response2.result
        if (Object.prototype.hasOwnProperty.call(response2.result, key)) {
          // If there is a matching key in response1.result, concatenate the arrays
          if (response1.result[key]) {
            mergedResult[key] = response1.result[key].concat(response2.result[key]);
          }
        }
      }
      // Return the merged result object
      return mergedResult;
    },
    getReiseterminHistory(startDate) {
      let url = '/history/Reisetermine/' + this.reiseterminObj.id;
      if (startDate) {
        url += `?from=${startDate}&until=${new Date().toISOString()}`;
      }
      return apiService.get(url).then(response => {
        return response.data.result;
      });
    },

    getReiseterminMetadataHistory(startDate) {
      let url = `/history/ReiseterminMetadata/${this.reiseterminObj.reise.id}/${this.reiseterminObj.startdatum}`;
      if (startDate) {
        url += `?from=${startDate}&until=${new Date().toISOString()}`;
      }
      return apiService.get(url).then(response => {
        return response.data.result;
      });
    },
    parseValuesByKeyName(columnName, value) {
      // TODO: Add missing column names
      const pluralizer = (count, noun, suffix = 'en') =>
        `${count} ${noun}${Math.abs(count) !== 1 ? suffix : ''}`;
      const parser = {
        Reisedauer: value => pluralizer(value, 'Tag'),
        Bearbeiter: value => this.$store.getters.getUsernameById(value),
        Besonderheiten: value => value,
        Frist: value => pluralizer(value, 'Tag'),
        Abreisedatum: value => this.$options.filters.date(value),
        AufschiebeDatum: value => this.$options.filters.date(value),
        AbsageDatum: value => this.$options.filters.date(value),
        KalkPax: value => pluralizer(value, 'Person'),
        MaxPax: value => pluralizer(value, 'Person'),
        MinPax: value => pluralizer(value, 'Person'),
        ReiseterminSyncState: value => value,
        Reiseterminkuerzel: value => value,
        Releasedatum: value => this.$options.filters.date(value),
        Startdatum: value => this.$options.filters.date(value),
        Umsatz: value => this.$options.filters.currency(value),
        BasisPreis: value => this.$options.filters.currency(value),
        Abgesagt: value => value,
        Garantiert: value => value,
        IstPax: value => pluralizer(value, 'Person'),
        OptPax: value => pluralizer(value, 'Person'),
        StopSale: value => value,
        KalkulierterFlugEk: value => this.$options.filters.currency(value),
        ReiseterminReleaseprozess_Einschraenkungen: value => value,
        ReiseterminReleaseprozess_ReiseunterlagenGeprueft: value => value,
        ReiseterminReleaseprozess_VertraglichVereinbarteQualitaetGeprueft: value => value,
        ReiseterminReleaseprozess_DmcHotels: value => value,
        ReiseterminReleaseprozess_DmcReiseleiter: value => value,
        ReiseterminReleaseprozess_DmcReiseablauf: value => value,
        ReiseterminReleaseprozess_DmcTransfers: value => value,
        ReiseterminReleaseprozess_GrundDurchfuehrung: value => value,
        ReiseterminState_State: value => value,
        ReiseterminWorkflowFrist_FristDatum: value => this.$options.filters.date(value),
        ReiseterminWorkflowFrist_Frist: value => pluralizer(value, 'Tag'),
        ReiseterminPruefprozess_TouristischeDurchfuehrbarkeit: value => value,
        IsDeleted: value => value,
        OvernightInbound: value => pluralizer(value, 'Tag'),
        OvernightOutbound: value => pluralizer(value, 'Tag'),
        Transferszenario: value => value,
      };
      if (parser[columnName] === undefined) {
        console.error(`Reisetermin History ${columnName} COLUMN not found`);
      }

      return parser[columnName] !== undefined ? parser[columnName](value) : value;
    },
  },
};
</script>
